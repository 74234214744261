import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import { Container } from 'react-bootstrap';

const SecondPage = () => {
  return (
    <Layout pageName='involved'>
      <Helmet>
        <title>EY - The enlightened session - Get involved</title>
      </Helmet>
      <Container>
        <div className='involved'>
          <h2>
            Contact your <span>EY partner</span> to involve your company in our
            enlightened sessions.
          </h2>
          <p>
            To find out more about the sessions and how they will run, contact:
          </p>
          <p>
            <strong>Sandra Winnik</strong>
            <br />
            <a href='mailto:Sandra.Winnik@de.ey.com?subject=Contact me about the enlightened sessions'>
              Sandra.Winnik@de.ey.com
            </a>
            <br />
            +49 160 939 26825
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default SecondPage;
